import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  alertMsg: '',
  status: false,
};

const globalAlertsSlice = createSlice({
  name: 'globalAlerts',
  initialState,
  reducers: {
    setGlobalAlert(state, action) {
      state.alertMsg = action.payload.alertMsg;
      state.status = action.payload.status;
    },
    clearGlobalAlert(state) {
      state.alertMsg = '';
      state.status = false;
    },
  },
});

export const globalAlertActions = globalAlertsSlice.actions;
export default globalAlertsSlice.reducer;

