// src/App.tsx
import React from 'react';
import "./languages";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
// import 'bootstrap-icons/font/bootstrap-icons.css';
// import 'react-loading-skeleton/dist/skeleton.css';
import './assets/styles/global.scss';
import './assets/styles/global-responsive.scss';
import AppRoutes from './features/routes';

const App: React.FC = () => {
    return (
        <div>
            <AppRoutes />
        </div>
    );
};

export default App;
