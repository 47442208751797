import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.scss';
import learningarea from "../../assets/images/Learningarea.png";
import generalapplication from "../../assets/images/generalapplication.png";
import usermanagement from "../../assets/images/Usermanagement.png";
import seatcreation from "../../assets/images/creation.png";
import leave from "../../assets/images/leave.png";
import thesis from "../../assets/images/thesis.png";
import workshop from "../../assets/images/workshop.png";
import bookallowance from "../../assets/images/book.png";
import hra from "../../assets/images/HRA.png";
import ehs from "../../assets/images/EHS.png";
import ltc from "../../assets/images/ltc.png";
import exammanagement from "../../assets/images/exam.png";
import convocation from "../../assets/images/convocation.png";
import { useSelector } from 'react-redux';

const Dashboard = () => {
    const userInfo = useSelector((state: any) => state.user.userInfo)
    console.log(userInfo,"userInfo")
    return (
        <>
            <div className='contentarea-wrapper'>
                <h2 className="page-title db-title">Student Management Area</h2>
                <div className="line-lessmargin"></div>
                <div className="sma-tile mt-3 mb-5">
                    <div className="row g-0">
                        <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
                            <a href='#' className="box">
                                <img src={learningarea} alt='Learning Area' />
                                <br />
                                <h6>Learning Area</h6>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
                            <a href='#' className="box">
                                <img src={generalapplication} alt='General Application' />
                                <br />
                                <h6>General Application</h6>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
                            <a href='#' className="box">
                                <img src={usermanagement} alt='User Management' />
                                <br />
                                <h6>User Management</h6>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
                            <a href='#' className="box">
                                <img src={seatcreation} alt='Seat Creation' />
                                <br />
                                <h6>Seat Creation</h6>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
                            <a href='#' className="box">
                                <img src={leave} alt='Leave' />
                                <br />
                                <h6>Leave</h6>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
                            <a href='#' className="box">
                                <img src={thesis} alt='Thesis' />
                                <br />
                                <h6>Thesis</h6>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
                            <a href='#' className="box">
                                <img src={workshop} alt='Conference and Workshop' />
                                <br />
                                <h6>Conference and Workshop</h6>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
                            <a href='#' className="box">
                                <img src={bookallowance} alt='Book Allowance' />
                                <br />
                                <h6>Book Allowance</h6>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
                            <a href='#' className="box">
                                <img src={hra} alt='HRA' />
                                <br />
                                <h6>HRA</h6>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
                            <a href='#' className="box">
                                <img src={ehs} alt='EHS' />
                                <br />
                                <h6>EHS</h6>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
                            <a href='#' className="box">
                                <img src={ltc} alt='LTC' />
                                <br />
                                <h6>LTC</h6>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
                            <a href='#' className="box">
                                <img src={exammanagement} alt='Exam Management' />
                                <br />
                                <h6>Exam Management</h6>
                            </a>
                        </div>
                        <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
                            <a href='#' className="box">
                                <img src={convocation} alt='Convocation' />
                                <br />
                                <h6>Convocation</h6>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
