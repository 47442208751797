import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const RedirectToOAuth = () => {
  const isLoggedIn = useSelector((state: any) => state.user.loggedIn);
  const navigate = useNavigate();
console.log(isLoggedIn)
  useEffect(() => {
    if (!isLoggedIn) {
    window.location.href = 'https://api.saral.ballisticlearning.com/oauth2-service/oauth2/authorize?response_type=code&client_id=moodle&redirect_uri=https://saral.ballisticlearning.com/authlogin&scope=openid&lang=en';
  }else{ 
    navigate("/dashboard")
  }
}, [isLoggedIn]);

return null; // No UI to render
};

export default RedirectToOAuth;
