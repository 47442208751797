import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Check if the user is logged in (check both Redux and localStorage)
const useAuth = () => {
  const isLoggedIn = useSelector((state: any) => state.user.loggedIn);
  
  // Check localStorage if Redux state is not available
  const localStorageLoggedIn = localStorage.getItem('loggedIn') === 'true';
  
  return isLoggedIn || localStorageLoggedIn; // Return true if either Redux or localStorage is logged in
};

function ProtectedRoutes() {
  const isAuth = useAuth();

  console.log('isAuth:', isAuth);

  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace />
  );
}

// Check if the user is an admin (check both Redux and localStorage)
const useAdmin = () => {
  const userInfo = useSelector((state: any) => state.user.userInfo);
  const isLoggedIn = useSelector((state: any) => state.user.loggedIn);

  // Check localStorage for user info if Redux state is not available
  const localStorageUserInfo = JSON.parse(localStorage.getItem('userInfo') || 'null');
  const localStorageLoggedIn = localStorage.getItem('loggedIn') === 'true';

  // If userInfo is available, check if they have admin role
  const userSiteAdmin = (userInfo || localStorageUserInfo)?.systemRoles 
    ? Object.values((userInfo || localStorageUserInfo).systemRoles).some(
        (role: any) => role.shortName === 'admin'
      )
    : false;

  // Return true if the user is an admin and logged in (either from Redux or localStorage)
  return (userSiteAdmin && (isLoggedIn || localStorageLoggedIn));
};

function ProtectedAdminRoutes() {
  const isAdmin = useAdmin();

  return isAdmin ? <Outlet /> : <Navigate to="/dashboard" replace />;
}

export { ProtectedRoutes, ProtectedAdminRoutes };
