// import React from 'react';
// import { Routes, Route, Outlet, useLocation } from 'react-router-dom';
// import PageNotFound from '../../pages/404';
// import Dashboard from '../../pages/dashboard';
// import HeaderIndex from '../../pages/commonComponents/header';
// import FooterIndex from '../../pages/commonComponents/footer';
// import MailTemplateConfiguration from '../../pages/mailTempelateConfiguration';
// import { ProtectedAdminRoutes, ProtectedRoutes } from './ProtectedRoutes';
// import RedirectToOAuth from '../../pages/redirectionToAuth/RedirectToOAuth';
// import { useSelector } from 'react-redux';
// import AuthLogin from '../../pages/authLogin/authLogin';
// import ForgotPassword from '../../pages/signuppage/forgetPassword/forgetpassword';
// import ResetPassword from '../../pages/signuppage/resetPassword/resetpassword';
// import Logout from '../../pages/logout';
// import InstituteManagement from '../../pages/institute';

// const AppRoutes: React.FC = () => {
//   const location = useLocation();
  
//   // const isLoggedIn = useSelector((state: any) => state.user.loggedIn);
// //   // Define paths where Header and Footer should NOT be visible
// //   const hideHeaderFooterPaths = ['/', '/forgotpassword', '/PageNotFound', '/authlogin'];
// //   const showHeaderFooter = isLoggedIn && !hideHeaderFooterPaths.includes(location.pathname);
// // console.log((location.pathname))

//   return (
//     <>
//      {location.pathname !== "/authlogin" && location.pathname !== "/" &&  location.pathname !== "/logout" && < HeaderIndex />}

//       <Routes>
//         <Route path="/" element={<RedirectToOAuth />} />
//         <Route path="/logout" element={<Logout />} />
//         <Route path="/authlogin" element={<AuthLogin />} />
//         <Route path="/forgotpassword" element={<ForgotPassword />} />
//         <Route path="/resetpassword" element={<ResetPassword />} />

//         {/* Wrap protected routes in ProtectedRoutes */}
//         <Route element={<ProtectedRoutes />}>
//           <Route path="/mailconfiguration" element={<MailTemplateConfiguration />} />
//           <Route path="/dashboard" element={<Dashboard />} />
//           <Route path="/lmsCongig" element={<InstituteManagement />} />
//           {/* Add other protected routes here */}
//         </Route>

//         <Route path="/PageNotFound" element={<PageNotFound />} />
//       </Routes>

//       {location.pathname !== "/authlogin" && location.pathname !== "/"  &&  location.pathname !== "/logout" &&   <FooterIndex />}
//     </>
//   );
// };


// export default AppRoutes;




// src/components/AppRoutes.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PageNotFound from '../../pages/404';
import Dashboard from '../../pages/dashboard';
import MailTemplateConfiguration from '../../pages/mailTempelateConfiguration';
import { ProtectedRoutes } from './ProtectedRoutes';  // Your ProtectedRoutes logic
import RedirectToOAuth from '../../pages/redirectionToAuth/RedirectToOAuth';
import AuthLogin from '../../pages/authLogin/authLogin';
import ForgotPassword from '../../pages/signuppage/forgetPassword/forgetpassword';
import ResetPassword from '../../pages/signuppage/resetPassword/resetpassword';
import InstituteManagement from '../../pages/institute';
import HeaderFooterLayout from '../../pages/commonComponents/HeaderFooterLayout';
import Logout from '../../pages/logout';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<HeaderFooterLayout />}> 
        <Route path="/" element={<RedirectToOAuth />} />
        <Route path="/authlogin" element={<AuthLogin />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/logout" element={<Logout />} />

        {/* Wrap protected routes in ProtectedRoutes */}
        <Route element={<ProtectedRoutes />}>
          <Route path="/mailconfiguration" element={<MailTemplateConfiguration />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/lmsconfig" element={<InstituteManagement />} />
          {/* Add other protected routes here */}
        </Route>

        <Route path="/PageNotFound" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
