import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { userActions } from "../../store/slices/userInfo"; // Assuming you have user slice
import { getData } from '../../adapters/coreservices';
import config from '../../utils/config';
const AuthLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const redirectUri = config.REDIRECT_URI;

  useEffect(() => {
    // Extract the code from URL params
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    // Dispatch action to store the authCode in Redux
    if (params.code) {
      dispatch(userActions.setAuthCode(params.code));
    }
  }, []);

  const authCode = useSelector((state: any) => state.user.authCode);

  useEffect(() => {
    if (authCode !== "") {
      // Verify the code
      const VERIFY_URL = `${config.OAUTH2_URL}/api/verifycode?code=${authCode}&redirect_uri=${redirectUri}`;

      var requestOptions: any = {
        method: 'GET',
        redirect: 'follow'
      };

      axios.get(VERIFY_URL,requestOptions)
        .then((response) => {
          console.log(response, "responce")
          if (response.data === '') {
            console.log('Verification failed: No token found', response);
            setIsLoaded(false);
            dispatch(userActions.setLoggedIn(false));
            navigate('/');
          } else {
            const result = response.data;
            setIsLoaded(true);
            dispatch(userActions.setLoggedIn(true));
              if ('access_token' in result) {
                Object.entries(result).map(([key, value]: any) => {
                  value = value.toString();
                  sessionStorage.setItem(key, value);
                  localStorage.setItem(key, value);  // added if app if reloaded for some url
                });
              // Dispatch the user token to Redux
              dispatch(userActions.setUserToken(result.access_token));
              localStorage.setItem("languages", JSON.stringify({ "hi": "Hindi", "en": "English" }));

              // Call user info endpoint
              getData('/user-info', {}).then((res: any) => {
                if (res.data !== "" && res.status === 200) {
                  // dispatch(userActions.userinfo(res.data));      //use when api is available
                  // Log user info to console
                  dispatch(userActions.setUserInfo(res.data));
                  const isAdmin = checkIfUserIsAdmin(res.data);
             dispatch(userActions.setUserAdmin(isAdmin))
                  navigate("/dashboard")
                }
              }).catch((error: any) => {
                console.error("Failed to fetch user info", error);
              });
            } else {
              console.log("User verification failed, no token found!")
              setIsLoaded(false);
              dispatch(userActions.setLoggedIn(false));
              navigate('/');
            }
          }
        })
        .catch((error) => {
          console.error('Error during verification', error);
          navigate('/');
        });
    } else {
      setIsLoaded(false);
      dispatch(userActions.setLoggedIn(false));
    }
  }, [authCode]);

  const checkIfUserIsAdmin = (userInfo: any): boolean => {
    return Object.values(userInfo.systemRoles || {}).some(
      (role: any) => role.shortName === "admin"
    );
  };

  const loaderStyle = {
    display: "flex",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <React.Fragment>
      <Container style={loaderStyle}>
        <div>
          {(!isLoaded && "Loading...")}
        </div>
      </Container>
    </React.Fragment>
  );
};

export default AuthLogin;
