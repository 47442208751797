// src/components/HeaderFooterLayout.tsx

import { useLocation, Outlet } from 'react-router-dom';
import './header.scss';
import chevrondown from "../../../assets/images/chevrondown.png";
import logowhite from "../../../assets/images/logowhite.png";
import logo from "../../../assets/images/logo.png";
import nficonurl from "../../../assets/images/marker.svg";
import ProfileDefaultImage from "../../../assets/images/profile.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell } from '@fortawesome/free-solid-svg-icons';
import '../../../assets/js/custom.js';
import { Dropdown } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { useNavigate, Link, NavLink } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FaCheck } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { useSelector } from 'react-redux';
import config from '../../../utils/config';
import home from "../../../assets/images/home.png";
import ahome from "../../../assets/images/ahome.png";
import course from "../../../assets/images/course.png";
import academicection from "../../../assets/images/academicection.png";
import examsection from "../../../assets/images/examsection.png";
import setfacility from "../../../assets/images/setfacility.png";
import faq from "../../../assets/images/faq.png";
import bellicon from "../../../assets/images/bellicon.png";
import ltcicon from "../../../assets/images/ltcicon.png";
import { getInitial } from '../../../globals/initialLetterofname/firstletterOfFirstOrLastName';


const HeaderFooterLayout = (): any => {
  const location = useLocation();
  // We want to hide the Header/Footer on some routes
  const hideHeaderFooterPaths = ['/', '/authlogin', '/forgotpassword', '/resetpassword', '/logout'];
  // Only render Header and Footer on routes that are not in the `hideHeaderFooterPaths` list
  const showHeaderFooter = !hideHeaderFooterPaths.includes(location.pathname);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/mailconfiguration");
  };
  const isAdmin = useSelector((state: any) => state.user.isAdmin);
  const isLoggedIn = useSelector((state: any) => state.user.loggedIn);
  const userInfo = useSelector((state: any) => state.user.userInfo)
  return (
    <>
      {/* header start  */}
      <div className="fixed-top main-header">
        <div id="verticalMainMenuCollapse" className="mainMenuToggle">
          <button type="button" className="btn btn-link hamburger" data-toggle="tooltip" data-placement="right" title="Toggle sidebar">
            <FontAwesomeIcon icon={faBars} />
          </button>
          <button type="button" className="btn btn-link chevrondown-par d-none" data-toggle="tooltip" data-placement="right" title="Toggle sidebar">
            <div className="chevrondown">
              <img src={chevrondown} alt="chevrondown" className="img-fluid" />
            </div>
          </button>
          <div className="secondary-logo">
            <a href="#"><img src={logowhite} alt="{{sitename}}" /></a>
          </div>
        </div>

        <nav className="navbar navbar-light bg-white navbar-expand global-header" aria-label="{{#str}}sitemenubar, admin{{/str}}">
          <div className="container-fluid">
            <div className="nav-box mh-logo">
              <a href="#"><img src={logo} alt="Logo" /></a>
            </div>
            {isLoggedIn &&
              <div className="nav-box ms-auto mh-usermenu">

                <div className="primary-navigation">
                  <ul className="nav more-nav navbar-nav">
                    <li className="nav-item">
                      <a className="nav-link" href="/dashboard">Dashboard</a>
                    </li>
                    {isAdmin &&
                      <li className="nav-item">
                        <a className="nav-link" href="#">Site administration</a>
                      </li>
                    }
                  </ul>
                </div>

                {/* Notification Start */}
                {isLoggedIn &&
                  <Nav as="ul" className="sh-toolbar">
                    <Nav.Item as="li" className="sh-notification">
                      <OverlayTrigger
                        trigger={'click'}
                        placement="bottom"
                        overlay={
                          <Popover id="popover-basic" className='alert-popover'>
                            <Popover.Header as="h3">
                              <div className='d-flex justify-content-between items-center'>
                                Notifications
                                <div>
                                  <span className='me-2 checkStatus'><FaCheck /></span>
                                  <a className='checkStatus' href='#'><IoSettingsOutline /></a>
                                </div>
                              </div>
                            </Popover.Header>
                            <Popover.Body className='alert-popover-body'>
                              <div className='all-notifications'>
                                <div className='content-item-container notification'>
                                  <a className='context-link' href='#'>
                                    <div className='content-item-body'>
                                      <div className='notification-image'><img src={nficonurl} alt="Notification" /></div>
                                      <div className='notification-message'>New sign in to your Moodle account</div>
                                    </div>
                                    <div className='content-item-footer'>
                                      <div className='timestamp'>8 days 21 hours ago</div>
                                    </div>
                                    <a className='view-more' href='#'>View full notification</a>
                                  </a>
                                </div>
                              </div>
                            </Popover.Body>
                            <div className='seeAlert'>
                              <a className='btn btn-link' href='#'>See all</a>
                            </div>
                          </Popover>
                        }
                        rootClose
                      >
                        <div className='notification-icon'>
                          <FontAwesomeIcon icon={faBell} />
                          <span>5</span>
                        </div>
                      </OverlayTrigger>
                    </Nav.Item>
                  </Nav>
                }
                {/* Notification Start */}

                <Dropdown>
                  <Dropdown.Toggle
                    variant="link"
                    id="dropdown-user-menu"
                    className="p-0"
                  >
                    <span className="rounded-circle user-profile-pix">
                      <span>{getInitial(userInfo)}</span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>Profile</Dropdown.Item>
                    <Dropdown.Item href={"/lmsconfig"}>LMS Configuration</Dropdown.Item>
                    <Dropdown.Item  href={"/mailconfiguration"}>Mail Configuration</Dropdown.Item>

                    
                    <Dropdown.Item href={`${config.OAUTH2_URL}/logout`}> Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <button className="navbar-toggler aabtn d-block d-md-none px-1 my-1 border-0" data-toggler="drawers" data-action="toggle" data-target="theme_healthcare-drawers-primary">
                  <span className="navbar-toggler-icon"></span>
                  <span className="sr-only">Toggle sidebar</span>
                </button>
              </div>}
          </div>
        </nav>
      </div>
      {/* header end  */}

      {/* side bar start */}
      <div id="globalContent" className="global-container">

        <div id="verticalMainMenu" className="mainmenu-container">
          <ul className="menu">
            <li>
              <a href="#" className="hometab">
                <div className="cus-white-box">
                  <img src={home} alt="Home" className="img-fluid" />
                  <h5>Home</h5>
                </div>
              </a>
            </li>
            <li>
              <a href="#" className="ahometab">
                <div className="cus-white-box">
                  <img src={ahome} alt="A Home" className="img-fluid" />
                  <h5>Saral Home</h5>
                </div>
              </a>
            </li>
            <li>
              <a href="#" className="course">
                <div className="cus-white-box">
                  <img src={course} alt="Course" className="img-fluid" />
                  <h5>Course at Saral</h5>
                </div>
              </a>
            </li>
            <li>
              <a href="#" className="academicsectiontab">
                <div className="cus-white-box">
                  <img src={academicection} alt="Academic Section" className="img-fluid" />
                  <h5>Academic Section</h5>
                </div>
              </a>
            </li>
            <li>
              <a href="#" className="examsectiontab">
                <div className="cus-white-box">
                  <img src={examsection} alt="Exam Section" className="img-fluid" />
                  <h5>Exam Section</h5>
                </div>
              </a>
            </li>
            <li>
              <a href="#" className="setfacilitytab">
                <div className="cus-white-box">
                  <img src={setfacility} alt="Set Facility" className="img-fluid" />
                  <h5>Set Facility</h5>
                </div>
              </a>
            </li>
            <li>
              <a href="#" className="faqtab">
                <div className="cus-white-box">
                  <img src={faq} alt="Faq" className="img-fluid" />
                  <h5>FAQ’s</h5>
                </div>
              </a>
            </li>
          </ul>

          {isLoggedIn &&
           <div id="verticalMainBell" className="MainMenuBell">
            <div id="bellBox" className="cus-bell-box">
              <div className="bellAlert">
                <span className="countStatus">2</span>
                <img src={bellicon} alt="Notification" className="img-fluid bell" />
              </div>
              <div className="ltcAlert">
                <span className="countStatus">1</span>
                <img src={ltcicon} alt="LTC" className="img-fluid" />
              </div>
            </div>
            <div className="cus-todo-block">5</div>
          </div>}
        </div>
        {/* side bar end  */}

{/* for render all pages in between header footer  */}
 {/* This will render the child routes here */}
        <main>
          <Outlet /> 
        </main>

      {/* footer start  */}
      </div>
      <footer className="page-footer text-center">
        <div className="copyright text-center mb-2">
          Copyright © 2024 Ballistic Learning Pvt Ltd. All rights reserved.
        </div>
      </footer>
      {/* footer End  */}
    </>
  );
};

export default HeaderFooterLayout;
